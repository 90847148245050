// Generated by Framer (a4dc481)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["L_9qce5WQ"];

const variantClassNames = {L_9qce5WQ: "framer-v-uzgyqa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 40, delay: 0, duration: 0, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 400, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "L_9qce5WQ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "L_9qce5WQ", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vCMp1", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-uzgyqa", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"L_9qce5WQ"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><motion.div className={"framer-xu33qm"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"fbZ9oWS0Y"} style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}} transition={transition}/><motion.div className={"framer-46ffzz"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"WXoIFAYD7"} style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vCMp1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vCMp1 * { box-sizing: border-box; }", ".framer-vCMp1 .framer-qgd65r { display: block; }", ".framer-vCMp1 .framer-uzgyqa { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 400px; }", ".framer-vCMp1 .framer-xu33qm, .framer-vCMp1 .framer-46ffzz { flex: none; height: 1px; overflow: hidden; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vCMp1 .framer-uzgyqa { gap: 0px; } .framer-vCMp1 .framer-uzgyqa > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-vCMp1 .framer-uzgyqa > :first-child { margin-top: 0px; } .framer-vCMp1 .framer-uzgyqa > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 */
const FramerCa8VI6usZ: React.ComponentType<Props> = withCSS(Component, css, "framer-vCMp1") as typeof Component;
export default FramerCa8VI6usZ;

FramerCa8VI6usZ.displayName = "Accordion Copy";

FramerCa8VI6usZ.defaultProps = {height: 2, width: 400};

addFonts(FramerCa8VI6usZ, [])